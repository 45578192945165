// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Emandate_container__5FTrF {
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
}

.Emandate_formControl__pHxvt {
    margin-bottom: 16px;
}

.Emandate_formContainer__mQMvs {
    padding: 10px;
}

.Emandate_section__8lqb- {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
}

.Emandate_legend__vfHef {
    font-weight: bold;
    color: #555;
}

.Emandate_button__TVMvo {
    margin-top: 20px;
    width: 100%;
}

.Emandate_toastContainer__SMrLk {
    font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Emandate/Emandate.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".container {\n    margin: 20px auto;\n    padding: 20px;\n    border-radius: 8px;\n}\n\n.formControl {\n    margin-bottom: 16px;\n}\n\n.formContainer {\n    padding: 10px;\n}\n\n.section {\n    margin-top: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 16px;\n    background-color: #fff;\n}\n\n.legend {\n    font-weight: bold;\n    color: #555;\n}\n\n.button {\n    margin-top: 20px;\n    width: 100%;\n}\n\n.toastContainer {\n    font-size: 0.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Emandate_container__5FTrF`,
	"formControl": `Emandate_formControl__pHxvt`,
	"formContainer": `Emandate_formContainer__mQMvs`,
	"section": `Emandate_section__8lqb-`,
	"legend": `Emandate_legend__vfHef`,
	"button": `Emandate_button__TVMvo`,
	"toastContainer": `Emandate_toastContainer__SMrLk`
};
export default ___CSS_LOADER_EXPORT___;
