import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import axios from "axios";

const PSegmentForm = ({ setFormData, formData, pSegmentErrors, backendUrl }) => {
  const handleInputChange = (field, value, reportType) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const getGeolocation = (segment, locationType) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          if (segment === "p") {
            if (locationType === "residence") {
              setFormData((prevData) => ({
                ...prevData,
                residenceGeoLocation: `Latitude: ${latitude}, Longitude: ${longitude}`,
              }));
            } else {
              setFormData((prevData) => ({
                ...prevData,
                workPlaceGeoLocation: `Latitude: ${latitude}, Longitude: ${longitude}`,
              }));
            }
          }
        },
        (error) => {
          console.error(`Geolocation error: ${error.message}`);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  };
  const handleImageUpload = async (field, e) => {
    let url;
    if (field === "residenceImages") {
      url = backendUrl + "/Psegement_inspections/uploadResidenceImages";
    } else {
      url = backendUrl + "/Psegement_inspections/uploadWorkPlaceImages";
    }
    const receivedFiles = e.target.files;
    // console.log(e.target.files[0]);
    try {
      const body = new FormData();
      for (let i = 0; i < receivedFiles.length; i++) {
        body.append("files", receivedFiles[i]);
      }
      const res = await axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success("File uploaded");
      }
    } catch (error) {
      toast.error("Error upload file");
    }
    const fileNames = Array.from(receivedFiles).map((file) => file.name);
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], ...fileNames],
    }));

    // setFormData((prevData) => ({
    //   ...prevData,
    //   [field]: [...prevData[field], ...files],
    // }));
  };

  return (
    <>
      <TextField
        label="Name"
        fullWidth
        value={formData.name}
        onChange={(e) => handleInputChange("name", e.target.value, "p")}
        required
        sx={{ mt: 1 }}
        error={!!pSegmentErrors.name}
        helperText={pSegmentErrors.name ? pSegmentErrors.name : ""}
      />
      <TextField
        label="Inspected By"
        fullWidth
        value={formData.inspectionBy}
        onChange={(e) => handleInputChange("inspectionBy", e.target.value, "p")}
        required
        error={!!pSegmentErrors.inspectionBy}
        helperText={pSegmentErrors.inspectionBy ? pSegmentErrors.inspectionBy : ""}
      />
      <TextField
        label="Inspection Date"
        name="createDateTime"
        value={formData.createDateTime}
        onChange={(e) => handleInputChange("createDateTime", e.target.value, "p")}
        type="datetime"
        InputLabelProps={{ shrink: true }}
        fullWidth
        required
        error={!!pSegmentErrors.createDateTime}
        helperText={pSegmentErrors.createDateTime ? pSegmentErrors.createDateTime : ""}
      />
      <TextField
        select
        label="Residence Visited"
        fullWidth
        sx={{ height: "45px" }}
        value={formData.residenceVisited}
        onChange={(e) => handleInputChange("residenceVisited", e.target.value, "p")}
        required
        SelectProps={{
          native: true, // Use native select element
        }}
        error={!!pSegmentErrors.residenceVisited}
        helperText={pSegmentErrors.residenceVisited ? pSegmentErrors.residenceVisited : ""}
      >
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </TextField>
      {formData.residenceVisited === "yes" && (
        <Stack gap={1}>
          <TextField
            label="Residence Address"
            fullWidth
            value={formData.residenceAddress}
            onChange={(e) => handleInputChange("residenceAddress", e.target.value, "p")}
            required
            error={!!pSegmentErrors.residenceAddress}
            helperText={pSegmentErrors.residenceAddress ? pSegmentErrors.residenceAddress : ""}
          />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="residence-image-upload"
            type="file"
            onChange={(e) => handleImageUpload("residenceImages", e)}
            multiple
          />
          <label htmlFor="residence-image-upload">
            <Button sx={{ color: "white !important" }} variant="contained" component="span">
              Upload Residence Images
            </Button>
          </label>
        </Stack>
      )}
      {formData?.residenceImages?.length > 0 && (
        <TableContainer sx={{ boxShadow: 0, border: "1px solid", overflowX: "visible" }}>
          <Table>
            <TableRow>
              <TableCell>Sr_No</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>

            <TableBody>
              {formData.residenceImages.map((image, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{image}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {formData.residenceVisited === "yes" && (
        <TextField
          label="Observations"
          fullWidth
          multiline
          rows={4}
          value={formData.residenceObservations}
          onChange={(e) => handleInputChange("residenceObservations", e.target.value, "p")}
          required
          error={!!pSegmentErrors.residenceObservations}
          helperText={
            pSegmentErrors.residenceObservations ? pSegmentErrors.residenceObservations : ""
          }
        />
      )}
    </>
  );
};

export default PSegmentForm;
