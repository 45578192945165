import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import styles from "./Inspections.module.css"; // Importing module.css
import axios from "axios";
import { styled } from "@mui/material/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  TextField,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IconReport } from "@tabler/icons-react";
import moment from "moment";

const LoanReviewAccounts = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const branch_code = sessionStorage.getItem("branch_code");
  const [products, setProducts] = useState([]);
  const [reviewed, setReviewed] = useState([]);
  const [notReviewed, setNotReviewed] = useState([]);
  // Watch 'type' field to enable/disable 'product' field
  const selectedType = watch("type");
  const formData = watch();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${backendUrl}/account/loan-review?acctType=${data.product}&BranchCode=${branch_code}&fromDate=${data.fromDate}&toDate=${data.toDate}`
      );
      setReviewed(res.data.reviewed || []);
      setNotReviewed(res.data.notReviewed || []);
      if (!res?.data?.reviewed?.length && !res?.data?.notReviewed?.length) {
        toast.info("0 Search Result");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async () => {
    const url = backendUrl + "/account/accountTypes";
    try {
      const res = await axios.get(url);
      setProducts(res.data);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  // Reset the product field when the type is changed
  useEffect(() => {
    resetField("product");
  }, [selectedType]);

  // view particular account
  const navigate = useNavigate();
  const handleView = (number, category) => {
    let visitUrl = `/loan-review/account/${number}`;
    navigate(visitUrl);
  };

  // reports
  const loanReviewPendingUrl = `${backendUrl}/account/loan-review/pending-accounts/downloadPdf?acctType=${formData?.product}&BranchCode=${branch_code}&fromDate=${formData?.fromDate}&toDate=${formData?.toDate}`;
  const loanReviewDoneUrl = `${backendUrl}/account/loan-review/downloadPdf?acctType=${formData?.product}&BranchCode=${branch_code}&fromDate=${formData?.fromDate}&toDate=${formData?.toDate}`;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? <Loader /> : null}

      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Type Field */}
          {/* <div className={styles.formGroup}>
            <TextField
              id="type"
              label="Type"
              select
              fullWidth
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...register("type", { required: "Type is required" })}
              error={!!errors.type}
              helperText={errors?.type?.message}
            >
              <option value="">Select type...</option>
              <option value="MSME">MSME</option>
              <option value="psegment">Retail</option>
            </TextField>
          </div> */}

          {/* Product Field */}
          <div className={styles.formGroup}>
            <TextField
              id="product"
              label="Product"
              select
              fullWidth
              variant="outlined"
              {...register("product", {
                required: "Product is required",
              })}
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.product}
              helperText={errors?.product?.message}
              disabled={products.length === 0}
            >
              <option value="">Select product...</option>
              {products.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.code} {option.name}
                </option>
              ))}
            </TextField>
          </div>

          {/* From Date Field */}
          <div className={styles.formGroup}>
            <TextField
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...register("fromDate", { required: "From Date is required" })}
              error={!!errors.fromDate}
              helperText={errors?.fromDate?.message}
            />
          </div>

          {/* To Date Field */}
          <div className={styles.formGroup}>
            <TextField
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...register("toDate", { required: "To Date is required" })}
              error={!!errors.toDate}
              helperText={errors?.toDate?.message}
            />
          </div>

          {/* Submit Button */}
          <Button
            className="ActionButtonsColor"
            disabled={loading}
            variant="contained"
            type="submit"
            fullWidth
          >
            Search
          </Button>
        </form>
      </div>

      {/* Tables */}
      {reviewed.length > 0 && (
        <div>
          <Typography variant="h5" sx={{ ml: 0.5, mb: 1 }}>
            Reviewed Accounts:
          </Typography>
          <Stack alignItems="flex-end">
            <Button
              sx={{ width: "200px", mb: "5px" }}
              download
              href={loanReviewDoneUrl}
              startIcon={<IconReport />}
            >
              Download Report
            </Button>
          </Stack>
          <TableContainer
            sx={{
              maxHeight: "400px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account Holder
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Outstand
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Review Date
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewed.map((row) => (
                  <StyledTableRow key={row.acctCd}>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctCd || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctNm || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.contact || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.amount || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {moment(row.createDateTime).format("DD-MM-YYYY / hh:mm") || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      <Button onClick={() => handleView(row.acctCd, row.npaCd)}>View</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {notReviewed.length > 0 && (
        <div style={{ marginTop: "30px" }}>
          <Typography variant="h5" sx={{ ml: 0.5, mb: 1 }}>
            Review Pending Accounts:
          </Typography>
          <Stack alignItems="flex-end">
            <Button
              sx={{ width: "200px", mb: "5px" }}
              download
              href={loanReviewPendingUrl}
              startIcon={<IconReport />}
            >
              Download Report
            </Button>
          </Stack>
          <TableContainer
            sx={{
              maxHeight: "400px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account Holder
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Outstand
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Place
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notReviewed.map((row) => (
                  <StyledTableRow key={row.acctCd}>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctCd || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctNm || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.contact || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.amount || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.city || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      <Button onClick={() => handleView(row.acctCd, row.npaCd)}>View</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </DashboardLayout>
  );
};

export default LoanReviewAccounts;
