// @mui/material imports
import {
  Button,
  Stack,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Custom components & libraries
import { useState, useEffect, useRef, forwardRef, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";

// Dashboard components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader";

// Transition for Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Styled Table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": { backgroundColor: "#edf6f9" },
}));

function Notice() {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(null);
  const [reviewUrl, setReviewUrl] = useState();
  const [previewLoading, setPreviewLoading] = useState(true);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { issuedDate: "" },
  });

  // Fetch Notices
  const getNotices = async () => {
    try {
      const { data, status } = await axios.get(`${backendUrl}/Notice/${id}`);
      if (status === 200) setFormData(data);
    } catch (error) {
      toast.error("Failed to fetch notices");
    } finally {
      setLoading(false);
    }
  };

  // Preview Notice
  const reviewNotice = async () => {
    try {
      const { data } = await axios.get(`${backendUrl}/Notice/preview/${id}`, {
        responseType: "arraybuffer",
      });
      const fileBlob = new Blob([data], { type: "application/pdf" });
      setReviewUrl(URL.createObjectURL(fileBlob));
    } catch (error) {
      toast.error("Failed to load preview");
    } finally {
      setPreviewLoading(false);
    }
  };

  // Submit Notice
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${backendUrl}/Notice/create`, {
        accountNumber: id,
        issuedTo: formData?.name,
        noticeType: formData?.noticeType,
        issuedDate: data.issuedDate,
      });
      if (response.status === 200) {
        toast.success("Notice Issued");
        setFormData((prev) => ({
          ...prev,
          history: [...prev.history, response.data],
        }));
        handleClose();
      }
    } catch (error) {
      toast.error("Error issuing notice");
    }
  };

  // Download Report
  const handleDownload = async (row) => {
    setIsDownloading(row.notice_id);
    try {
      const { data } = await axios.get(`${row.report}${row.notice_id}`, { responseType: "blob" });
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `report_${row.notice_id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      toast.error("Download failed");
    } finally {
      setIsDownloading(null);
    }
  };

  // Open & Close Modal
  const handleNewNotice = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    reset();
  };

  useEffect(() => {
    getNotices();
    reviewNotice();
  }, []);

  const noticeHistory = useMemo(() => formData.history || [], [formData.history]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ textAlign: "center" }} variant="h4">
            Notice History
          </Typography>
          <TableContainer sx={{ maxHeight: "calc(100vh - 220px)", border: "1px solid lightgray" }}>
            <Table>
              <TableHead>
                <TableRow className="NewTableHeader" sx={{ zIndex: 999 }}>
                  {["Account No", "Notice Type", "Issued On", "Issued To", "Report"].map(
                    (header) => (
                      <TableCell
                        className="NewTableHeaderFontColor"
                        key={header}
                        sx={{ fontWeight: "700" }}
                      >
                        {header}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {noticeHistory.length ? (
                  noticeHistory.map((row) => (
                    <StyledTableRow key={row?.accountNumber}>
                      {["accountNumber", "noticeType", "issuedDate", "issuedTo"].map((field) => (
                        <TableCell className="NewTableCells" key={field}>
                          {field === "issuedDate" && row[field]
                            ? moment(row[field]).isValid()
                              ? moment(row[field]).format("DD-MM-YYYY / HH:mm")
                              : "---"
                            : row[field] || "---"}
                        </TableCell>
                      ))}

                      <TableCell>
                        {row.report ? (
                          <Button onClick={() => handleDownload(row)} disabled={isDownloading}>
                            {isDownloading === row.notice_id ? "Downloading..." : "Download"}
                          </Button>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button className="ActionButtonsColor" variant="contained" onClick={handleNewNotice}>
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}

      {/* Modal */}
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Record New Notice</DialogTitle>
        <DialogContent>
          {previewLoading ? (
            <Stack justifyContent={"center"} alignItems="center" height={300}>
              <CircularProgress size={24} />
            </Stack>
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px" }}>
                <Stack alignItems={"flex-end"} mb={1}>
                  <Controller
                    name="issuedDate"
                    control={control}
                    rules={{
                      required: "Issued Date is required",
                      validate: (value) =>
                        moment(value).isSameOrBefore(moment()) || "Date cannot be in the future",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        label="Issued Date"
                        type="datetime-local"
                        inputProps={{
                          max: new Date().toISOString().slice(0, 16), // Current date and time
                        }}
                        error={!!errors.issuedDate}
                        helperText={errors.issuedDate?.message}
                      />
                    )}
                  />
                </Stack>
              </form>
              <iframe height={500} width="100%" src={reviewUrl}></iframe>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={previewLoading || isSubmitting} onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
          <Button disabled={previewLoading || isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Notice;
