import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Stack,
  Dialog,
  DialogContent,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./Emandate.module.css";
import { toast } from "react-toastify";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DeleteConfirmationDialog from "components/Comman/DeleteConfirmationDialog";
import { IconDownload, IconTrash } from "@tabler/icons-react";

export default function Emandate() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const accountNumber = route[route.length - 1];
  const [eMandateHistory, setEMandateHistory] = useState([]);
  const [openEmandate, setOpenEmandate] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [enteredOTP, setEnteredOTP] = useState("");
  const [aadhaarInput, setAadhaarInput] = useState("");
  const [isOptGenerated, setIsOtpGenerated] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    if (enteredOTP == "537618" || enteredOTP == "879172" || enteredOTP == "693654") {
      try {
        const url = backendUrl + "/emandates";
        const res = await axios.post(url, {
          ...data,
          accountNumber,
        });
        setEMandateHistory((prev) => [...prev, res?.data]);
        reset();
        toast.success("Sucessfully verified!");
        toast.success("Form submitted successfully!");
        handleCloseMandate();
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
      }

      return;
    }
    toast.warn("Please enter correct otp");
  };

  const handleGenerateOtp = () => {
    if (aadhaarInput.length !== 12 || isNaN(aadhaarInput)) {
      toast.error("Enter a valid 12-digit Aadhaar number!");
      return;
    }
    setIsOtpGenerated(true);
    setIsOtpSent(true);
    setTimer(120); // Set timer to 120 seconds (2 minutes)
    toast.success("OTP sent successfully!");
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval); // Cleanup timer on component unmount
    } else {
      setIsOtpSent(false); // Enable the "Send OTP" button after timer ends
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
  };
  const handleChange = (value) => {
    setEnteredOTP(value);
  };

  const getHisory = async () => {
    try {
      const url = backendUrl + `/emandates/account/${accountNumber}`;
      const res = await axios.get(url);
      setEMandateHistory(res.data || []);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
  const getAccountDetails = async () => {
    const url = backendUrl + `/emandates/fetch/${accountNumber}`;
    try {
      const res = await axios.get(url);
      reset(
        {
          debitAcc: { amount: res?.data?.installmentAmount },
          creditAcc: { loanAccNo: res?.data?.accountNumber },
        },
        { keepDirtyValues: true }
      );
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
  const handleNewMandate = () => {
    setOpenEmandate(true);
  };
  const handleCloseMandate = () => {
    setOpenEmandate(false);
  };
  useEffect(() => {
    getHisory();
    getAccountDetails();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = async () => {
    const url = backendUrl + `/emandates/${deleteId}`;
    try {
      await axios.delete(url);
      const updateList = eMandateHistory?.filter((item) => item.id !== deleteId);
      setEMandateHistory(updateList);
      setDeleteId(null);
      toast.success("Record deleted sucessfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    setDialogOpen(false);
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setDeleteId(null);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isSubmitting && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      )}
      <TableContainer
        sx={{
          my: 3,
          maxHeight: "calc(100vh - 220px)",
          boxShadow: 0,
          border: "1px solid lightgray",
          overflow: "auto",
        }}
      >
        <Table sx={{ minWidth: "600px" }}>
          <TableHead sx={{ width: "100%", display: "contents" }}>
            <TableRow
              className="NewTableHeader"
              sx={{
                backgroundColor: "#49a3f1",
                color: "white !important",
                position: "sticky",
                top: 0,
                zIndex: 999,
              }}
            >
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
              >
                A/C No
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
              >
                Frequency
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
              >
                Start Date
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
              >
                End Date
              </TableCell>
              {/* <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
              >
                Report
              </TableCell> */}
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eMandateHistory.length ? (
              eMandateHistory?.map((row, index) => (
                <StyledTableRow key={index}>
                  <TableCell className="NewTableCells" align="left">
                    {row?.accountNumber ? row?.accountNumber : "---"}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row?.frequency ? row?.frequency : "---"}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row?.startDate ? row?.startDate : "---"}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row?.endDate ? row?.endDate : "---"}
                  </TableCell>
                  {/* <TableCell className="NewTableCells" align="left">
                    {row.report ? (
                      <a href={row.report} download>
                        <Button>Download</Button>
                      </a>
                    ) : (
                      "---"
                    )}
                  </TableCell> */}
                  <TableCell className="NewTableCells" align="center">
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setDialogOpen(true);
                          setDeleteId(row.id);
                        }}
                      >
                        <IconTrash color="red" />
                      </IconButton>
                    </Tooltip>
                    {/* <a  download> */}
                    <Tooltip title="Download">
                      <IconButton
                        download
                        component="a"
                        href={`${backendUrl}/emandates/downloadPdf/${row.id}`}
                      >
                        <IconDownload color="#1A73E8" />
                      </IconButton>
                    </Tooltip>
                    {/* </a> */}
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack alignItems="flex-end">
        <Button className="ActionButtonsColor" variant="contained" onClick={handleNewMandate}>
          Add New
        </Button>
      </Stack>
      <Dialog maxWidth="lg" fullWidth open={openEmandate} onClose={handleCloseMandate}>
        <DialogTitle>eMandate Form</DialogTitle>
        <DialogContent>
          <Box className={styles.formContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Start Date */}
              <Box mb={3}>
                <TextField
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  {...register("startDate", { required: "Start date is required" })}
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message}
                />
              </Box>

              {/* End Date */}
              <Box mb={3}>
                <TextField
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  {...register("endDate", { required: "End date is required" })}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                />
              </Box>
              <Box mb={3}>
                <TextField
                  label="Mobile Number"
                  type="number"
                  fullWidth
                  {...register("mobileNumber", { required: "Mobile number is required" })}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                />
              </Box>
              <Box mb={3}>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  {...register("email", { required: "email is required" })}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                />
              </Box>

              {/* Frequency */}
              <Box mb={3}>
                <TextField
                  select
                  label="Frequency"
                  fullWidth
                  sx={{ height: "50px" }}
                  {...register("frequency", { required: "Frequency is required" })}
                  SelectProps={{
                    native: true, // Use native select element
                  }}
                  required
                  error={!!errors.frequency}
                  helperText={errors.frequency?.message}
                >
                  <option value="month">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="half-yearly">Half-Yearly</option>
                  <option value="yearly">Yearly</option>
                  {/* Add more event types as needed */}
                </TextField>
              </Box>

              {/* Debit Account Details */}
              <Box mb={3} className={styles.section}>
                <Typography variant="h6" gutterBottom>
                  Debit Account Details
                </Typography>
                <Divider />
                <Box mt={2}>
                  <TextField
                    label="Bank Account Number"
                    fullWidth
                    {...register("debitAcc.bankAccNo", {
                      required: "Bank account number is required",
                    })}
                    error={!!errors.debitAcc?.bankAccNo}
                    helperText={errors.debitAcc?.bankAccNo?.message}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    label="IFSC Code"
                    fullWidth
                    {...register("debitAcc.ifscCode", { required: "IFSC code is required" })}
                    error={!!errors.debitAcc?.ifscCode}
                    helperText={errors.debitAcc?.ifscCode?.message}
                  />
                </Box>
                <Box mt={2}>
                  <TextField label="MICR Code" fullWidth {...register("debitAcc.micrCode")} />
                </Box>
                <Box mt={2}>
                  <TextField
                    label="Amount"
                    type="number"
                    fullWidth
                    {...register("debitAcc.amount", { required: "Amount is required" })}
                    error={!!errors.debitAcc?.amount}
                    helperText={errors.debitAcc?.amount?.message}
                  />
                </Box>
              </Box>

              {/* Credit Account Details */}
              <Box mb={3} className={styles.section}>
                <Typography variant="h6" gutterBottom>
                  Credit Account Details
                </Typography>
                <Divider />
                <Box mt={2}>
                  <TextField
                    label="Loan Account Number"
                    fullWidth
                    {...register("creditAcc.loanAccNo", {
                      required: "Loan account number is required",
                    })}
                    error={!!errors.creditAcc?.loanAccNo}
                    helperText={errors.creditAcc?.loanAccNo?.message}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    label="IFSC Code"
                    fullWidth
                    {...register("creditAcc.ifscCode", { required: "IFSC code is required" })}
                    error={!!errors.creditAcc?.ifscCode}
                    helperText={errors.creditAcc?.ifscCode?.message}
                  />
                </Box>
                <Box mt={2}>
                  <TextField label="MICR Code" fullWidth {...register("creditAcc.micrCode")} />
                </Box>
              </Box>

              {/* Aadhaar Verification */}
              <Typography variant="h6" textAlign="center" my={1}>
                Adhaar Verification
              </Typography>
              <Stack mb={3} flexDirection={"row"} gap={1}>
                <TextField
                  label="Aadhaar Number"
                  fullWidth
                  value={aadhaarInput}
                  type=""
                  onChange={(e) => setAadhaarInput(e.target.value)}
                />
                <Box>
                  <Button
                    variant="contained"
                    className="ActionButtonsColor"
                    color="primary"
                    sx={{ minWidth: "120px", height: "100%" }}
                    fullWidth
                    onClick={handleGenerateOtp}
                    disabled={isOtpSent} // Disable button while OTP is active
                  >
                    {isOtpSent ? `Resend OTP in ${formatTime(timer)}` : "Send OTP"}
                  </Button>
                </Box>
              </Stack>
              <Box mb={3}>
                <TextField
                  disabled={!isOptGenerated}
                  label="Enter OTP"
                  fullWidth
                  onChange={(e) => handleChange(e.target.value)}
                />
              </Box>

              {/* Submit Button */}
              <Box mb={3}>
                <Button
                  disabled={isSubmitting || !enteredOTP}
                  type="submit"
                  variant="contained"
                  fullWidth
                  className="ActionButtonsColor"
                >
                  {isSubmitting ? <CircularProgress size={22} /> : "Verify & Submit"}
                </Button>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <DeleteConfirmationDialog
        open={dialogOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
        title="Confirm Deletion"
        message="Do you really want to delete this item? This action cannot be undone."
        confirmText="Yes, Delete"
        cancelText="No, Cancel"
      />
    </DashboardLayout>
  );
}
