import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";

const DeleteConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
  title = "Delete Confirmation",
  message = "Are you sure you want to delete this item? This action cannot be undone.",
  confirmText = "Delete",
  cancelText = "Cancel",
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          className="ActionButtonsColor"
          onClick={onCancel}
          variant="contained"
        >
          {cancelText}
        </Button>
        <Button
          disabled={loading}
          className="ActionButtonsColor"
          onClick={onConfirm}
          variant="contained"
        >
          {loading ? <CircularProgress size={22} /> : confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
