import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button, Typography, Box, IconButton, Paper, LinearProgress, Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader";
import styles from "./Upload.module.css";
import axios from "axios";
import { IconFileText } from "@tabler/icons-react";
import { toast } from "react-toastify";

// Styled Components
const UploadContainer = styled(Box)({
  width: "400px",
  backgroundColor: "#f4f8fb",
  borderRadius: "10px",
  border: "1px solid lightgray",
  padding: "20px",
  textAlign: "center",
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
});

const FileRow = styled(Paper)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  marginTop: "10px",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
});

const Upload = () => {
  const { register, handleSubmit } = useForm();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  // Handle form submission
  const onSubmit = async () => {
    if (files.length === 0) {
      setError("No file selected for upload.");
      return;
    }

    setLoading(true);
    setError(null);

    await uploadFile(files[0]); // Upload the single file

    setLoading(false);
  };

  // Upload a single file
  // Upload a single file
  const uploadFile = async (fileObj) => {
    const formData = new FormData();
    formData.append("file", fileObj.file); // Backend expects a single file

    try {
      const response = await axios.post(`${backendUrl}/jobs/importCustomers`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const loaded = progressEvent.loaded;
          const percentage = Math.round((loaded * 100) / total);

          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file.name === fileObj.file.name
                ? { ...f, progress: percentage > 100 ? 100 : percentage }
                : f
            )
          );
        },
      });

      toast.success("Upload successful");
      setFiles([]); // Clear the files after successful upload
    } catch (err) {
      console.error("Upload failed:", err);
      setError("Upload failed. Please try again.");
    }
  };

  // Handle file drop
  const onDrop = (e) => {
    e.preventDefault();
    const newFile = e.dataTransfer.files[0]; // Only handle the first dropped file
    handleFiles([newFile]);
  };

  // Handle file selection via input
  const handleFileUpload = (event) => {
    const newFile = event.target.files[0]; // Only handle the first selected file
    handleFiles([newFile]);
  };

  // Process and validate the file
  const handleFiles = (newFiles) => {
    const txtFile = newFiles.find((file) => file?.name?.endsWith(".txt"));

    if (!txtFile) {
      setError("Only .txt files are supported.");
    } else {
      setError(null);
      const fileWithProgress = {
        file: txtFile,
        progress: 0,
      };
      setFiles([fileWithProgress]); // Replace the existing file
    }
  };

  // Remove the file from the list
  const handleRemoveFile = () => {
    setFiles([]);
  };

  const handleClickUploadArea = () => {
    fileInputRef.current.click();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <Loader />}
      <div className={styles.container}>
        <UploadContainer>
          <Typography variant="h6" gutterBottom>
            UPLOAD FILE
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Upload Area */}
            <div
              onDragOver={(e) => e.preventDefault()}
              onDrop={onDrop}
              onClick={handleClickUploadArea}
              style={{
                border: "2px dashed #ccc",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "#e9f4fb",
                cursor: "pointer",
              }}
            >
              <CloudUploadIcon fontSize="large" style={{ color: "#90caf9" }} />
              <Typography variant="body1">DRAG AND DROP</Typography>
              <Typography variant="body2">or Browse</Typography>
              <input
                type="file"
                style={{ display: "none" }}
                {...register("file")}
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept=".txt"
              />
            </div>

            {/* Error Message */}
            {error && (
              <Typography color="error" variant="body2" style={{ marginTop: "10px" }}>
                {error}
              </Typography>
            )}

            {/* List of Files */}
            {files.length > 0 && (
              <FileRow>
                <Box display="flex" alignItems="center">
                  <IconFileText size={24} />
                  <Stack ml={2} alignItems="flex-start">
                    <Typography variant="body2">{files[0].file.name}</Typography>
                    <Typography variant="body2">
                      {(files[0].file.size / 1024).toFixed(2)} Kb
                    </Typography>
                  </Stack>
                </Box>

                <Box display="flex" alignItems="center">
                  {files[0].progress > 0 && (
                    <LinearProgress
                      variant="determinate"
                      value={files[0].progress}
                      style={{ width: "100px", marginRight: "10px" }}
                    />
                  )}
                  <IconButton aria-label="cancel" onClick={handleRemoveFile}>
                    <CancelIcon />
                  </IconButton>
                </Box>
              </FileRow>
            )}

            {/* Upload Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="ActionButtonsColor"
              sx={{ marginTop: "20px", width: "100%" }}
              disabled={files.length === 0 || loading}
            >
              Upload
            </Button>
          </form>
        </UploadContainer>
      </div>
    </DashboardLayout>
  );
};

export default Upload;
