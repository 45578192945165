import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { TextField, Box, Typography, Button, CircularProgress } from "@mui/material";

// Images
import bgImage from "assets/Logos/bank_bg.jpg"; // Professional banking background
import logo from "assets/Logos/logoVarachha3.png";
import SideImageLogin from "assets/Logos/SideImageLogin.jpg";
import axios from "axios";
import { toast } from "react-toastify";
function SignIn() {
  const login_url = process.env.REACT_APP_LOGIN_URL;
  // console.log(login_url);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(login_url, {
        employee_no: data.employee_no,
        password: data.password,
      });
      if (response?.data?.branch_code?.toString() == "Sahkar Bhavan") {
        sessionStorage.setItem("branch_code", "");
      } else {
        sessionStorage.setItem("branch_code", response.data.branch_code);
      }
      sessionStorage.setItem("email", response.data.email_id);
      sessionStorage.setItem("branchName", response.data.branch_name);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during sign in", error);
      toast.error("An error occurred during login. Please try again.");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}
    >
      <Grid item sx={{ width: { md: "min(800px, 95%)", xs: "min(400px, 95%)" } }}>
        <Card
          style={{ padding: "0px", borderRadius: "10px", boxShadow: "0 4px 12px rgba(0,0,0,0.1)" }}
        >
          <Grid container>
            <Grid item xs={12} md={6} sx={{ padding: { xs: "20px", md: "30px" } }}>
              <Box textAlign="center">
                <Typography variant="h4" fontWeight="bold" color="#555555">
                  Login To CMS
                </Typography>
              </Box>
              <img
                style={{
                  width: "120px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginBottom: "40px",
                }}
                src={logo}
                alt="logo"
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={3}>
                  <TextField
                    fullWidth
                    label="Employee Number"
                    variant="outlined"
                    {...register("employee_no", { required: "Employee Number is required" })}
                    error={!!errors.employee_no}
                    helperText={errors.employee_no ? errors.employee_no.message : ""}
                  />
                </Box>

                {/* Password Field */}
                <Box mb={3}>
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    {...register("password", { required: "Password is required" })}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                  />
                </Box>

                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{ padding: "10px 0", borderRadius: "5px", color: "#ffffff", mb: 2 }}
                >
                  {isSubmitting ? <CircularProgress size={22} /> : "Sign In"}
                </Button>
              </form>
            </Grid>
            <Grid
              item
              xs={0}
              sm={6}
              sx={{ borderLeft: "2px solid lightgray", display: { xs: "none", md: "grid" } }}
            >
              <img
                style={{ width: "100%", height: "100%", borderRadius: "0 10px 10px 0" }}
                src={SideImageLogin}
                alt="logo"
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SignIn;
