import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Typography,
  Box,
  IconButton,
  Paper,
  LinearProgress,
  Stack,
  Grid,
  Tooltip,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader";
import axios from "axios";
import { IconFileText } from "@tabler/icons-react";
import { toast } from "react-toastify";

// Styled Components
const UploadContainer = styled(Box)({
  width: "100%",
  maxWidth: "400px",
  backgroundColor: "#f4f0fa",
  borderRadius: "12px",
  border: "1px solid #d1c4e9",
  padding: "25px",
  textAlign: "center",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  margin: "0 auto",
});

const FileRow = styled(Paper)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "15px",
  marginTop: "15px",
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  border: "1px solid #d1c4e9",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const Upload = () => {
  const { register, handleSubmit } = useForm();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const onSubmit = async () => {
    if (files.length === 0) {
      setError("No file selected for upload.");
      return;
    }

    setLoading(true);
    setError(null);

    await uploadFile(files[0]); // Upload the single file

    setLoading(false);
  };

  const uploadFile = async (fileObj) => {
    const formData = new FormData();
    formData.append("file", fileObj.file);

    try {
      await axios.post(`${backendUrl}/jobs/importCustomers`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const loaded = progressEvent.loaded;
          const percentage = Math.round((loaded * 100) / total);

          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file.name === fileObj.file.name
                ? { ...f, progress: percentage > 100 ? 100 : percentage }
                : f
            )
          );
        },
      });

      toast.success("Upload successful");
      setFiles([]);
    } catch (err) {
      console.error("Upload failed:", err);
      setError("Upload failed. Please try again.");
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    const newFile = e.dataTransfer.files[0];
    handleFiles([newFile]);
  };

  const handleFileUpload = (event) => {
    const newFile = event.target.files[0];
    handleFiles([newFile]);
  };

  const handleFiles = (newFiles) => {
    const txtFile = newFiles.find((file) => file?.name?.endsWith(".txt"));

    if (!txtFile) {
      setError("Only .txt files are supported.");
    } else {
      setError(null);
      const fileWithProgress = {
        file: txtFile,
        progress: 0,
      };
      setFiles([fileWithProgress]);
    }
  };

  const handleRemoveFile = () => {
    setFiles([]);
  };

  const handleClickUploadArea = () => {
    fileInputRef.current.click();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Loader />
        </Box>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <UploadContainer>
            <Typography variant="h6" gutterBottom style={{ fontWeight: "bold", color: "#512da8" }}>
              Upload Your File
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={onDrop}
                onClick={handleClickUploadArea}
                style={{
                  border: "2px dashed #7e57c2",
                  borderRadius: "12px",
                  padding: "30px",
                  backgroundColor: "#f3e5f5",
                  cursor: "pointer",
                  transition: "background-color 0.3s, border-color 0.3s",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.borderColor = "#9575cd")}
                onMouseLeave={(e) => (e.currentTarget.style.borderColor = "#7e57c2")}
              >
                <CloudUploadIcon
                  fontSize="large"
                  style={{ color: "#7e57c2", marginBottom: "10px" }}
                />
                <Typography variant="body1" style={{ fontWeight: "bold", color: "#512da8" }}>
                  Drag and Drop your file here
                </Typography>
                <Typography variant="body2" style={{ color: "#7e57c2" }}>
                  or click to browse
                </Typography>
                <input
                  type="file"
                  style={{ display: "none" }}
                  {...register("file")}
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  accept=".txt"
                />
              </div>

              {error && (
                <Typography
                  color="error"
                  variant="body2"
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#fce4ec",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  {error}
                </Typography>
              )}

              {files.length > 0 && (
                <FileRow>
                  <Box display="flex" alignItems="center">
                    <IconFileText size={24} style={{ color: "#512da8" }} />
                    <Stack ml={2} alignItems="flex-start">
                      <Typography variant="body2" style={{ color: "#512da8" }}>
                        {files[0].file.name}
                      </Typography>
                      <Typography variant="body2" style={{ color: "#7e57c2" }}>
                        {(files[0].file.size / 1024).toFixed(2)} Kb
                      </Typography>
                    </Stack>
                  </Box>

                  <Box display="flex" alignItems="center">
                    {files[0].progress > 0 && (
                      <LinearProgress
                        variant="determinate"
                        value={files[0].progress}
                        style={{
                          width: "100px",
                          marginRight: "10px",
                          color: "#9575cd",
                        }}
                      />
                    )}
                    <Tooltip title="Remove file">
                      <IconButton aria-label="cancel" onClick={handleRemoveFile}>
                        <CancelIcon style={{ color: "#7e57c2" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </FileRow>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginTop: "20px",
                  color: "white !important",
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "#7e57c2",
                  "&:hover": {
                    backgroundColor: "#512da8",
                  },
                  "&:disabled": {
                    backgroundColor: "#d1c4e9",
                  },
                }}
                disabled={files.length === 0 || loading}
              >
                Upload File
              </Button>
            </form>
          </UploadContainer>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Upload;
