import React, { useState, useEffect } from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Box,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Interview() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newInterviewModal, setNewInterviewModal] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      personInterviewed: "",
      interviewerName: "",
      createDateTime: "",
      interviewMinutes: "",
      place: "",
      summary: "",
      interviewImagesName: [],
    },
  });

  const watchFiles = watch("interviewImagesName");

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);

    // Validate file types - Allow only images
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    const invalidFiles = files.filter((file) => !file.type.startsWith("image/"));

    if (invalidFiles.length > 0) {
      toast.error("Only image files are allowed!");
      return; // Stop execution if invalid files exist
    }

    if (imageFiles.length === 0) {
      toast.error("No valid image files selected!");
      return;
    }

    try {
      const formData = new FormData();
      imageFiles.forEach((file) => formData.append("files", file));

      const res = await axios.post(`${backendUrl}/Interviews/uploadImages`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.status === 200) {
        const fileNames = imageFiles.map((file) => file.name);
        setValue("interviewImagesName", [...watchFiles, ...fileNames]);
        toast.success("Files uploaded successfully");
      }
    } catch (error) {
      toast.error("File upload failed");
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = watchFiles.filter((_, i) => i !== index);
    console.log(updatedFiles);
    setValue("interviewImagesName", updatedFiles); // Update the form state
    toast.success("File removed successfully");
  };

  const submitForm = async (data) => {
    try {
      const res = await axios.post(`${backendUrl}/Interviews/create`, {
        ...data,
        accountNumber: id,
      });
      if (res.status === 200) {
        toast.success("Interview recorded successfully");
        reset();
        fetchInterviewHistory();
      }
    } catch {
      toast.error("Unable to save interview");
    }
    setNewInterviewModal(false);
  };

  const fetchInterviewHistory = async () => {
    try {
      const res = await axios.get(`${backendUrl}/Interviews/history/${id}`);
      if (res.status === 200) setData(res.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccountDetails = async () => {
    try {
      const res = await axios.get(`${backendUrl}/account/getName?acctNo=${id}`);
      setValue("personInterviewed", res.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchInterviewHistory();
    fetchAccountDetails();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": { backgroundColor: "#edf6f9" },
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Interview History
          </Typography>
          <TableContainer sx={{ maxHeight: "calc(100vh - 220px)", border: "1px solid lightgray" }}>
            <Table>
              <TableHead>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  {[
                    "Person Interviewed",
                    "Interviewer Name",
                    "Date",
                    "Place",
                    "Summary",
                    "Report",
                  ].map((header) => (
                    <TableCell
                      className="NewTableHeaderFontColor"
                      key={header}
                      sx={{ fontWeight: "700" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((row) => (
                    <StyledTableRow key={row.interview_id}>
                      <TableCell className="NewTableCells">
                        {row.personInterviewed || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells">
                        {row.interviewerName || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells">
                        {row.createDateTime
                          ? moment(row.createDateTime).format("DD-MM-YYYY hh:mm")
                          : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells">{row.place || "---"}</TableCell>
                      <TableCell className="NewTableCells">{row.summary || "---"}</TableCell>
                      <TableCell className="NewTableCells">
                        {row.report ? (
                          <a href={row.report} download>
                            <Button>Download</Button>
                          </a>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              className="ActionButtonsColor"
              onClick={() => setNewInterviewModal(true)}
              variant="contained"
              sx={{ background: "#49a3f1", color: "white" }}
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}

      <Dialog
        open={newInterviewModal}
        TransitionComponent={Transition}
        onClose={() => (isSubmitting ? null : setNewInterviewModal(false))}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Record New Interview</DialogTitle>
        <DialogContent>
          <Stack gap={2} p={1}>
            {[
              { name: "personInterviewed", label: "Person Interviewed" },
              { name: "interviewerName", label: "Interviewer Name" },
              { name: "createDateTime", label: "Interview Date", type: "date" },
              { name: "interviewMinutes", label: "Interview Minutes", type: "number" },
              { name: "place", label: "Place" },
              { name: "summary", label: "Interview Summary", multiline: true, rows: 4 },
            ].map(({ name, label, type, ...props }) => (
              <Controller
                key={name}
                name={name}
                control={control}
                rules={{ required: `${label} is required` }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    {...props}
                    type={type || "text"}
                    label={label}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            ))}
            <TextField
              label="Upload Files"
              type="file"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                multiple: true,
                accept: "image/*",
              }}
              onChange={(e) => handleFileUpload(e)}
              fullWidth
            />
            <TableContainer sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow
                    className="NewTableHeader"
                    sx={{
                      backgroundColor: "#49a3f1",
                      color: "white !important",
                      position: "sticky",
                      top: 0,
                      zIndex: 999,
                    }}
                  >
                    <TableCell className="NewTableHeaderFontColor">Sr. No</TableCell>
                    <TableCell className="NewTableHeaderFontColor">File Name</TableCell>
                    <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watchFiles?.length ? (
                    watchFiles.map((file, index) => (
                      <TableRow key={index}>
                        <TableCell className="NewTableCells">{index + 1}</TableCell>
                        <TableCell className="NewTableCells">{file}</TableCell>
                        <TableCell className="NewTableCells">
                          <Button onClick={() => handleFileDelete(index)}>Delete</Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>No data</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            className="ActionButtonsColor"
            variant="contained"
            onClick={handleSubmit(submitForm)}
          >
            Submit
          </Button>
          <Button disabled={isSubmitting} onClick={() => setNewInterviewModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Interview;
